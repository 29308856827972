<template>
  <index-content>
    <template #options>
      <nb-refresh @on-click="load" />
      <nb-reset @on-ok="onBtnReset" />
    </template>

    <div class="n-table-total-size">{{ totalText }}: {{ records.length }}</div>

    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th>ID</th>
            <th class="appId">{{ $t('label.appId') }}</th>
            <th class="appName">{{ $t('label.appName') }}</th>
            <th class="services">{{ $t('label.services') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th class="action">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.appId }}</td>
            <td>{{ row.appName | hyphen }}</td>
            <td class="n-text-right">{{ row.serviceCount | hyphen }}</td>
            <td>{{ row.timestamp | datetime }}</td>
            <td class="n-button-box">
              <n-button
                text="detail"
                custom-icon="fas fa-info-circle"
                :disabled="row.services.length === 0"
                @on-click="onBtnDetail(row)"
              />
              <n-button
                text="query"
                :disabled="row.querying"
                :custom-icon="row.querying ? 'fa fa-spin fa-spinner' : 'fa fa-search'"
                @on-click="onBtnQuery(row)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="n-table-total-size">{{ totalText }}: {{ records.length }}</div>
  </index-content>
</template>

<script>
import SystemView from './view';
import ModalService from './ModalService';
import { sortBy, forEach } from 'lodash-es';
import { capitalize } from '@nextop/admin-utils';
import { fetch, reset, query } from '@/api/system/system-service';

export default SystemView.extend({
  name: 'SystemService',

  components: { ModalService },

  data() {
    return {
      preload: true,
      totalText: this.$t('label.total'),
    };
  },

  methods: {
    doLoad() {
      return fetch();
    },

    parse(rows) {
      forEach(rows, r => {
        r.querying = false;
        r.serviceCount = r.services.length || '';
      });
      this.records = sortBy(rows, ['appId']);
    },

    onBtnReset() {
      return reset();
    },

    onBtnDetail(row) {
      const customTitle = this.$t('modal.detail', { name: capitalize(row.appName) });
      this.createModal(ModalService, { model: row, props: { customTitle } });
    },

    onBtnQuery(row) {
      row.querying = true;
      query(row.id)
        .then(() => {
          this.load();
        })
        .finally(() => (row.querying = false));
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 750px;

  th.action {
    width: 9rem;
  }
}
</style>
