import { $fetch, $post } from '@/services/axios';

export function fetch() {
  return $fetch(`system/service`);
}

export function reset() {
  return $post(`system/service/reset`);
}

export function query(id) {
  return $fetch(`system/service/${id}`);
}
