<template>
  <n-modal v-bind="$props" :detail="true">
    <div class="p-scroll-container table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="id">{{ $t('label.id') }}</th>
            <th class="domain">{{ $t('label.domain') }}</th>
            <th class="type">{{ $t('label.type') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in model.services" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.domain }}</td>
            <td>{{ row.type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'SystemServiceDetail',
  components: { NModal },
});
</script>
<style lang="scss" scoped>
.domain {
  width: 24%;
}
.p-scroll-container {
  max-height: 500px;
  overflow-y: auto;
}
.n-table {
  min-width: 700px;
}
</style>
